import { axios } from '@/plugins/vueaxios'
import TreadDepthThresholds from '@/models/tyre/TreadDepthThresholds'

export default class TreadDepthThresholdsService {
  public static loadConfig(): Promise<TreadDepthThresholds> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/tyre/tread-depth-threshold')
        .then((response) => {
          resolve(new TreadDepthThresholds(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: TreadDepthThresholds): Promise<TreadDepthThresholds> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/tyre/tread-depth-threshold', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
