































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import TreadDepthThresholdsService from '@/services/tyre/TreadDepthThresholdsService'
import TreadDepthThresholds from '@/models/tyre/TreadDepthThresholds'

@Component
export default class TreadDepthThresholdsConfig extends Vue {
  private isSaving = false
  private data: TreadDepthThresholds = null
  private rules = {}
  private error = ''

  public created(): void {
    this.data = new TreadDepthThresholds()
    this.rules = {
      winterCriticalThreshold: [
        (v) => {
          if (!v || v < 0) {
            return this.$t('c:validation:This field is required')
          }
          if (v >= this.data.winter.warning) {
            return this.$t('c:tyre:tread-depth-thresholds:Critical must be less than warning')
          }
          return true
        },
      ],
      winterWarningThreshold: [
        (v) => {
          if (!v || v < 0) {
            return this.$t('c:validation:This field is required')
          }
          if (v <= this.data.winter.critical) {
            return this.$t('c:tyre:tread-depth-thresholds:Critical must be less than warning')
          }
          return true
        },
      ],
      summerCriticalThreshold: [
        (v) => {
          if (!v || v < 0) {
            return this.$t('c:validation:This field is required')
          }
          if (v >= this.data.summer.warning) {
            return this.$t('c:tyre:tread-depth-thresholds:Critical must be less than warning')
          }
          return true
        },
      ],
      summerWarningThreshold: [
        (v) => {
          if (!v || v < 0) {
            return this.$t('c:validation:This field is required')
          }
          if (v <= this.data.summer.critical) {
            return this.$t('c:tyre:tread-depth-thresholds:Critical must be less than warning')
          }
          return true
        },
      ],
    }
    this.load()
  }

  private load() {
    TreadDepthThresholdsService.loadConfig()
      .then((TreadDepthThresholds) => {
        if (!TreadDepthThresholds) {
          vxm.alert.onAxiosError(this.$t('c:tyre:tread-depth-thresholds:Update load tread depth thresholds config'))
          return
        }
        this.data = TreadDepthThresholds
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    this.isSaving = true
    TreadDepthThresholdsService.update(this.data)
      .then(() => {
        vxm.alert.success('c:tyre:tread-depth-thresholds:Successfully updated')
      })
      .catch((err) => {
        this.isSaving = false
        vxm.alert.onAxiosError(err, this.$t('c:tyre:tread-depth-thresholds:Unable to update thresholds config'))
      })
  }
}
