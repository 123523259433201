import ThresholdsLevel from './ThresholdsLevel'

export default class TreadDepthThresholds {
  public winter: ThresholdsLevel
  public summer: ThresholdsLevel

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {
        winter: {
          critical: 0,
          warning: 0,
        } as ThresholdsLevel,
        summer: {
          critical: 0,
          warning: 0,
        } as ThresholdsLevel,
      }
    }
    this.winter = data.winter as ThresholdsLevel
    this.summer = data.summer as ThresholdsLevel
  }

  public clone(): TreadDepthThresholds {
    const result = new TreadDepthThresholds()
    Object.assign(result, this)
    return result
  }
}
